<template>
  <div>
    <ProfileTitle
      v-if="profile"
      :profile="profile"
      :title="$t('Pages.Profile.education')"
      array="educations"
      button-id="education"
      @button-event="addEducation"
    />
    <transition-group
      id="education-container"
      name="slide-y-transition"
      tag="v-layout"
      class="row wrap resume-cards"
    >
      <v-col
        v-for="(education, index) in profile.educations"
        :key="education.id"
        cols="12"
        sm="6"
      >
        <ProfileCard
          icon="school"
          :title="getEducationLevel(education.degreeLevel)"
          :subtitle="education.degree"
          :fill-height="true"
          :menu-id="'more-education-' + index"
          :list="[
            // {
            //   id: 'edit-education-' + index,
            //   clickEvent: 'edit-item',
            //   text: $t('Common.edit')
            // },
            {
              id: 'delete-education-' + index,
              clickEvent: 'delete-item',
              text: $t('Common.delete')
            },
          ]"
          @edit-item="eduList.push(education.degree), currentEducation = { degree: education.degree, degreeLevel: education.degreeLevel , index: index}, educationDialog = true"
          @delete-item="itemToDelete = education, deleteEducationDialog = true"
        />
      </v-col>
    </transition-group>
    <transition name="slide-y-transition">
      <EmptySectionText
        v-if="!profile.educations || !profile.educations.length"
        icon="school"
        :title="$t('Pages.Profile.school_is')"
      />
    </transition>
    <v-dialog v-model="educationDialog">
      <CustomDialog
        title-icon="add_circle"
        :title="$t('Pages.Profile.add_education')"
        :second-title="$t('Pages.Profile.add_education_text')"
      >
        <div slot="custom">
          <div class="wrapper-div">
            <div class="wrapper-div">
              <v-select
                id="education-degree"
                v-model="currentEducation.degreeLevel"
                :items="degrees"
                :error="showError"
                color="accent"
                :label="$t('Pages.Search.degree')"
                menu-props="bottom"
                item-value="value"
                clearable
                autofocus
              />
            </div>
            <div
              id="education-name"
              class="wrapper-div"
            >
              <v-text-field
                id="education-field-input"
                v-model="currentEducation.degree"
                outlined
                rounded
                dense
                color="accent"
                :label="$t('Pages.Search.field')"
              />
            </div>
          </div>
        </div>
        <div
          slot="buttons"
          class="button-container"
        >
          <v-btn
            id="close-education-btn"
            text
            rounded
            @click="educationDialog = false"
            v-text="$t('Common.cancel')"
          />
          <v-btn
            id="another-education-btn"
            text
            rounded
            :disabled="(currentEducation.degreeLevel === '') || (currentEducation.degreeLevel === '') || (educationErrors && educationErrors.length > 0)"
            @click="formatInput(true)"
            v-text="$t('Pages.Profile.add_another')"
          />
          <v-btn
            id="save-education-btn"
            text
            rounded
            :disabled="(currentEducation.degreeLevel === '') || (currentEducation.degreeLevel === '') || (educationErrors && educationErrors.length > 0)"
            class="cta-btn"
            @click="formatInput()"
            v-text="$t('Common.save')"
          />
        </div>
      </CustomDialog>
    </v-dialog>
    <v-dialog
      v-model="deleteEducationDialog"
      max-width="500"
    >
      <CustomDialog
        title-icon="delete"
        :title="$t('Pages.Profile.delete_education')"
        :second-title="$t('Pages.Profile.delete_education_text')"
        :action-button="{
          class: 'deleteAction',
          id: 'delete-education-confirm-btn',
          text: $t('Common.delete')
        }"
        :close-button="{
          id: 'delete-edu-cancel-btn',
          text: $t('Common.cancel')
        }"
        @close-event="deleteEducationDialog = false"
        @perform-action="handleDelete"
      />
    </v-dialog>
  </div>
</template>

<script>
import EmptySectionText from '@/pages/profile/edit/components/EditProfileEmptySection.vue'
import ProfileTitle from '@/pages/profile/edit/components/EditProfileTitles.vue'
import ProfileCard from '@/pages/profile/components/ProfileCard.vue'
import CustomDialog from '@/common/CustomDialog.vue'
import EventBus from '@/common/EventBus.js'
export default {
  components: {
    ProfileTitle,
    ProfileCard,
    CustomDialog,
    EmptySectionText
  },
  props: {
    profile: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      educationDialog: false,
      degrees: [
        { text: this.$t('Pages.Profile.doctorate_or_higher'), value: 'doctorate_or_higher' },
        { text: this.$t('Pages.Profile.licentiate'), value: 'licentiate' },
        { text: this.$t('Pages.Profile.masters'), value: 'masters' },
        { text: this.$t('Pages.Profile.bachelors'), value: 'bachelors' },
        { text: this.$t('Pages.Profile.secondary'), value: 'secondary' },
        { text: this.$t('Pages.Profile.primary'), value: 'primary' },
        { text: this.$t('Pages.Profile.higher_vocational'), value: 'higher_vocational' },
        { text: this.$t('Pages.Profile.course'), value: 'course' },
        { text: this.$t('Pages.Profile.certification'), value: 'certification' }
      ],
      deleteEducationDialog: false,
      itemToDelete: null,
      educationErrors: [],
      showError: false,
      currentEducation: '',
      eduList: []
    }
  },
  watch: {
    'currentEducation.degree': function (newVal) {
      if (!newVal) return
      if (newVal.length && newVal.replace(/^\s+|\s+$/gm, '').length < 1) {
        this.educationErrors = 'Cannot contain only spaces'
      } else {
        this.educationErrors = []
      }
    }
  },
  created () {
    EventBus.$on('duplicateErrorseducations', (error) => {
      this.educationErrors = error
    })
    EventBus.$on('savededucations', (keepOpen) => {
      if (!keepOpen) {
        this.educationDialog = false
      } else {
        this.currentEducation = { degreeLevel: '', degree: '', index: this.profile.educations.length + 1 }
      }
    })
    EventBus.$on('deleteDialogeducations', () => {
      this.deleteEducationDialog = false
    })
  },
  beforeDestroy () {
    EventBus.$off('duplicateErrorseducation')
    EventBus.$off('savededucation')
    EventBus.$off('deleteDialogeducation')
  },
  methods: {
    formatInput (keepOpen) {
      if (!this.currentEducation.degree) {
        this.educationErrors = 'This field is required'
        return this.educationErrors
      } else if (this.currentEducation.degree.degree) {
        this.currentEducation.degree = this.currentEducation.degree.degree
      }
      this.$emit('save-content', this.currentEducation, 'educations', keepOpen)
    },
    handleDelete () {
      this.$emit('delete-item', this.itemToDelete, 'educations')
    },
    addEducation () {
      this.clearEduErrors()
      this.suggesting = false
      this.currentEducation = { degree: '', degreeLevel: '', index: this.profile.educations.length }
      this.educationDialog = true
    },
    getEducationLevel (level) {
      var degrees = {
        no_formal: this.$t('Pages.Profile.no_formal'),
        course: this.$t('Pages.Profile.course'),
        certification: this.$t('Pages.Profile.certification'),
        higher_vocational: this.$t('Pages.Profile.higher_vocational'),
        primary: this.$t('Pages.Profile.primary'),
        secondary: this.$t('Pages.Profile.secondary'),
        bachelors: this.$t('Pages.Profile.bachelors'),
        masters: this.$t('Pages.Profile.masters'),
        licentiate: this.$t('Pages.Profile.licentiate'),
        doctorate_or_higher: this.$t('Pages.Profile.doctorate_or_higher')
      }
      for (var i in degrees) {
        if (level === i) {
          return degrees[i]
        }
      }
    },
    clearEduErrors () {
      this.educationErrors = []
      this.nameErrors = []
    },
    removeFocus (ref, list) {
      if (!this[list]) return
      if (this[list].length === 1) {
        this.$refs[ref].blur()
      }
    }
  }
}
</script>
