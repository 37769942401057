<template>
  <div>
    <v-col class="text-center">
      <h4 v-text="$t('Disc.disc')" />
    </v-col>
    <p v-html="$t('Disc.desc')" />
    <div class="disc-img-container">
      <img
        style="width: 100%;"
        src="@/assets/img/sampleDISC.png"
        alt="Sample DISC"
      >
      <h4>Sample DISC</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiscBasicText'
}
</script>
