<template>
  <div>
    <v-card
      class="ipu-card rounded-xl"
      color="neutral"
      flat
    >
      <v-card-title class="justify-center">
        <h4 class="text-center">
          {{ $t('Pages.Profile.behavioral_assessment') }}
        </h4>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-0 pb-6 px-0">
        <div
          v-if="isMotivations || (!isMotivations && unfinishedLink)"
          class="margin-layout"
        >
          <div>
            <div
              v-if="(!isMotivations && unfinishedLink)"
              class="mx-4 mt-3"
            >
              <h3 v-text="$t('Pages.Profile.you_have')" />
              <p v-text="$t('Pages.Profile.click_on_the')" />
              <h3 v-text="$t('Pages.Profile.you_already')" />
              <p v-text="$t('Pages.Profile.please_wait')" />
            </div>
            <div class="d-flex align-center justify-center">
              <p
                v-if="!isMotivations && unfinishedLink"
                class="mx-4 mb-2 d-flex"
              >
                <v-btn
                  :href="unfinishedLink"
                  target="_blank"
                  class="cta-btn mb-2"
                  rounded
                  text
                  depressed
                  rel="noreferrer"
                  v-text="$t('Pages.Profile.continue_analysis')"
                />
              </p>
            </div>
          </div>
          <v-tabs
            v-if="profile.ipu && profile.ipu.disc && profile.ipu.disc[0]"
            v-model="ipuTabs"
            show-arrows
            centered
            background-color="neutral"
            color="secondary"
            grow
          >
            <v-tab>
              <v-icon>poll</v-icon>
            </v-tab>
            <v-tab>
              <v-icon>pie_chart</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-show="profile.ipu && profile.ipu.disc && profile.ipu.disc.length"
            v-model="ipuTabs"
            class="ipu-tabs neutral"
          >
            <v-tab-item class="pb-5 pt-4">
              <!-- <div class="text-center text-h5 mb-4 pb-4">Personality Traits</div> -->
              <h3
                v-if="profile.ipu && profile.ipu.disc && profile.ipu.disc[0]"
                class="text-center pt-2 mb-4 pb-4"
                v-text="$t('Disc.personality_traits')"
              />
              <v-row
                v-if="profile.ipu && profile.ipu.disc && profile.ipu.disc[0]"
                class="ipu-layout my-4"
              >
                <v-tooltip
                  bottom
                  color="light-blue darken-4"
                >
                  <template #activator="{ on }">
                    <div
                      id="redbar"
                      :style="'height: ' + profile.ipu.disc[0].d + 'px'"
                      class="ipu-bar"
                      v-on="on"
                    >
                      <span class="bar-value top">
                        <strong>{{ profile.ipu.disc[0].d }}</strong>
                      </span>
                      <span class="bar-value bottom">
                        <strong>D</strong>
                      </span>
                    </div>
                  </template>
                  <span>{{ profile.ipu.disc[0].d }}% Dominance</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  color="light-blue darken-4"
                >
                  <template #activator="{ on }">
                    <div
                      id="yellowbar"
                      :style="'height: ' + profile.ipu.disc[0].i + 'px'"
                      class="ipu-bar"
                      v-on="on"
                    >
                      <span class="bar-value top">
                        <strong>{{ profile.ipu.disc[0].i }}</strong>
                      </span>
                      <span class="bar-value bottom">
                        <strong>I</strong>
                      </span>
                    </div>
                  </template>
                  <span>{{ profile.ipu.disc[0].i }}% Influence</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  color="light-blue darken-4"
                >
                  <template #activator="{ on }">
                    <div
                      id="greenbar"
                      :style="'height: ' + profile.ipu.disc[0].s + 'px'"
                      class="ipu-bar"
                      v-on="on"
                    >
                      <span class="bar-value top">
                        <strong>{{ profile.ipu.disc[0].s }}</strong>
                      </span>
                      <span class="bar-value bottom">
                        <strong>S</strong>
                      </span>
                    </div>
                  </template>
                  <span>{{ profile.ipu.disc[0].s }}% Steadiness</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  color="light-blue darken-4"
                >
                  <template #activator="{ on }">
                    <div
                      id="bluebar"
                      :style="'height: ' + profile.ipu.disc[0].c + 'px'"
                      class="ipu-bar"
                      v-on="on"
                    >
                      <span class="bar-value top">
                        <strong>{{ profile.ipu.disc[0].c }}</strong>
                      </span>
                      <span class="bar-value bottom">
                        <strong>C</strong>
                      </span>
                    </div>
                  </template>
                  <span>{{ profile.ipu.disc[0].c }}% Conscientiousness</span>
                </v-tooltip>
              </v-row>
            </v-tab-item>
            <v-tab-item class="pb-2 pt-4">
              <div class="text-center text-h5 mb-4">
                {{ $t('Disc.behavior') }}
              </div>
              <div
                v-if="profile.ipu && profile.ipu.wheel && profile.ipu.wheel.length"
                class="ipu-wheel behavioral-profile"
              >
                <v-tooltip
                  max-width="200"
                  top
                  color="light-blue darken-4"
                >
                  <template #activator="{ on }">
                    <div
                      is="DiscPie"
                      id="piediv"
                      :personality="profile.ipu.wheel && profile.ipu.wheel.length ? profile.ipu.wheel[0].personality : ''"
                      class="pieContainer"
                      v-on="on"
                    >
                      <div
                        slot="background"
                        class="pieBackground"
                      />
                    </div>
                  </template>
                  <span>
                    <div class="types">
                      <strong v-if="profile.ipu && profile.ipu.wheel && profile.ipu.wheel.length">
                        {{ profile.ipu.wheel[0].personality }}
                      </strong>
                      <strong v-else>
                        No position
                      </strong>
                    </div>
                  </span>
                </v-tooltip>
              </div>
              <div
                v-if="profile.ipu && profile.ipu.wheel && profile.ipu.wheel.length"
                class="ma-3"
              >
                <div class="text-center mb-2">
                  <strong>{{ profile.ipu.wheel[0].name }}</strong>
                </div>
                <p>{{ getPersonalityText(profile.ipu.wheel[0].name) }}</p>
              </div>
              <div
                v-else
                class="ma-3"
              >
                The result of the DISC assessment indicates that {{ user.firstName }} has an equal behavioural
                preference to the four categories (D, I, S and C). Hence it is not possible to locate {{
                  user.firstName
                }} in the wheel above. Please use the histogram view instead.
              </div>
            </v-tab-item>
          </v-tabs-items>
          <div
            v-if="profile.ipu && profile.ipu.ipuDrivingForces && profile.ipu.ipuDrivingForces.length"
            class="flex-column behavioral-profile pt-3"
          >
            <h3
              class="text-center mb-2 pb-3"
              v-text="$t('Disc.driving_forces')"
            />
            <div
              v-for="(motivation, index) in profile.ipu.ipuDrivingForces"
              :key="index"
              class="driving-container"
            >
              <v-tooltip
                max-width="200"
                top
                color="light-blue darken-4"
              >
                <template #activator="{ on }">
                  <v-chip
                    :class="getColor(motivation.name)"
                    class="justify-center"
                    text-color="white"
                    v-on="on"
                  >
                    {{ motivation.name }}
                  </v-chip>
                </template>
                <span>{{ getMotivationsText(motivation.name) }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <div
          v-if="false"
          class="ipu-controlls margin-top-switch"
        >
          <v-tooltip
            bottom
            color="light-blue darken-4"
          >
            <template #activator="{ on }">
              <v-switch
                v-if="isMotivations"
                id="behavior-id"
                v-model="profile.ipu.ipuIsVisible"
                :label="profile.ipu.ipuIsVisible === true ? $t('Disc.show') : $t('Disc.hide')"
                class="text-center"
                style="width: 7.5rem;"
                color="accent"
                v-on="on"
                @change="toggleMotivations()"
              />
            </template>
            <span>{{ $t('Disc.hide_show_tooltip') }}</span>
          </v-tooltip>
        </div>
        <div
          v-if="ipuProduct && (!profile.ipu || !profile.ipu.disc || !profile.ipu.disc.length)"
          class="ipu-cards"
        >
          <div v-if="!unfinishedLink">
            <div
              v-if="paymentLoader === false"
              class="pa-0"
            >
              <v-tabs
                v-model="readMoreTabsMobile"
                grow
                slider-color="accent"
                color="secondary"
                background-color="neutral"
                disabled
              >
                <v-tab>
                  {{ $t('Disc.quick') }}
                </v-tab>
              </v-tabs>
              <v-tabs-items
                v-model="readMoreTabsMobile"
                class="ipu-purchase-cards neutral"
              >
                <v-tab-item>
                  <v-card
                    :class="{ glowCard: ipuProduct && ipuProduct.price === 0 }"
                    class="elevation-0 my-0"
                    color="neutral"
                    flat
                  >
                    <img
                      src="@/assets/img/waveIpu-01.svg"
                      class="wave-ipu"
                      alt="Card wave detail"
                    >
                    <v-card-text class="pb-0">
                      <div class="icon-container">
                        <v-icon>star_outline</v-icon>
                      </div>
                      <v-col

                        class="check-list mt-4 mb-2 shrink"
                      >
                        <v-row
                          class="my-2"

                          align="center"
                        >
                          <v-icon
                            color="green"
                            class="mr-3"
                          >
                            check
                          </v-icon>
                          <span class="text-body-2">
                            {{ $t('Disc.behavioral') }}
                          </span>
                        </v-row>
                        <v-row
                          class="my-1"

                          align="center"
                        >
                          <v-icon
                            color="green"
                            class="mr-3"
                          >
                            check
                          </v-icon>
                          <span class="text-body-2">
                            {{ $t('Disc.driving') }}
                          </span>
                        </v-row>
                      </v-col>
                    </v-card-text>
                    <v-card-actions class="d-block">
                      <v-col
                        class="text-center mb-0"
                      >
                        <div
                          v-if="ipuProduct && ipuProduct.price === 0"
                          class="limited-price"
                        >
                          <h3>Limited time offer!</h3>
                        </div>
                        <div
                          v-if="ipuProduct && ipuProduct.price !== 0"
                          class="limited-price"
                        />
                        <div
                          v-if="ipuProduct"
                          class="my-2"
                        >
                          <h3 class="price-text mb-0">
                            {{ ipuProduct.price / 100 }}
                            <small>SEK</small>
                          </h3>
                          <small>({{ ipuProduct.price / 100 * 0.75 }} SEK ex. VAT)</small>
                        </div>
                      </v-col>
                      <v-col
                        class="text-center mb-0"
                      >
                        <v-btn
                          id="read-more-disc"
                          color="primary"
                          rounded
                          depressed
                          @click="readMoreTabs = 0; readMoreIpu = true"
                          v-text="$t('Common.read_more')"
                        />
                      </v-col>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="readMoreIpu"
      content-class="read-more-ipu rounded-xl"
    >
      <v-card
        flat
        color="neutral"
        class="rounded-xl"
      >
        <v-stepper
          id="disc-stepper"
          v-model="discPaymentStepper"
          non-linear
        >
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card-text
                v-if="paymentLoader === false"
                class="pa-0"
              >
                <v-tabs
                  v-model="readMoreTabs"
                  show-arrows
                  centered
                  background-color="neutral"
                  color="secondary"
                  grow
                >
                  <v-tab>
                    <v-icon>star_border</v-icon>
                    Basic
                  </v-tab>
                </v-tabs>
                <v-tabs-items
                  v-model="readMoreTabs"
                  class="neutral"
                >
                  <v-tab-item>
                    <v-card
                      flat
                      color="neutral"
                      class="rounded-xl"
                    >
                      <v-card-text>
                        <DiscBasicText
                          class="mb-3"
                        />
                        <router-link
                          :to="{ name: 'disc' }"
                          target="_blank"
                          rel="noreferrer"
                          v-text="$t('Pages.Profile.read_more_analysis')"
                        />
                        <div
                          v-if="ipuProduct"
                          class="mb-2 mt-4"
                        >
                          <v-col class="text-center">
                            <h3 class="price-text mb-0">
                              {{ ipuProduct.price / 100 }}
                              <small>SEK</small>
                            </h3>
                            <small>({{ ipuProduct.price / 100 * 0.75 }} SEK ex. VAT)</small>
                          </v-col>
                        </div>
                        <div
                          v-if="!unfinishedLink && showPurchase === true"
                          class="mb-2 mt-3"
                        >
                          <v-col
                            v-if="ipuProduct"
                            class="text-center"
                          >
                            <v-btn
                              v-if="ipuProduct && ipuProduct.price === 0"
                              class="cta-btn"
                              @click="choice = 'ipuBasic'; priceZero()"
                            >
                              {{ $t('Pages.Auth.continue') }}
                            </v-btn>
                            <!--
                            <v-btn
                              v-else
                              color="teal lighten-1"
                              large
                              depressed
                              rounded
                              @click="purchaseIpuBasic(); choice = 'ipuBasic'; discPaymentStepper = 2; initiateStripe()"
                            >
                              {{ $t('Disc.purchase') }}
                            </v-btn>
                            -->
                            <v-btn
                              v-else
                              color="teal lighten-1"
                              large
                              depressed
                              rounded
                              @click="onBuyDisc()"
                            >
                              {{ $t('Disc.purchase') }}
                            </v-btn>
                          </v-col>
                          <v-col
                            v-if="ipuProduct"
                            class="text-center my-2"
                          >
                            <small style="font-style: italic;">
                              {{ $t('Disc.clicking') }}
                            </small>
                          </v-col>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card-title class="text-h6 font-weight-regular">
                {{ $t('Disc.disc') }}
                <v-spacer />
                <img
                  src="@/assets/img/powered_by_stripe.svg"
                  height="26"
                  class="mx-auto"
                  style="height: 26px; width: 119px;"
                  type="image/svg+xml"
                  alt="stripe"
                >
              </v-card-title>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <v-card-actions
          v-if="paymentLoader === false"
          class="button-container"
        >
          <v-spacer />
          <v-btn
            v-if="discPaymentStepper === 2"
            text
            rounded
            @click="discPaymentStepper = 1"
          >
            {{ $t('Common.back') }}
          </v-btn>
          <v-btn
            text
            rounded
            @click="readMoreIpu = false"
          >
            {{ $t('Common.close') }}
          </v-btn>
        </v-card-actions>
        <v-card-text v-if="paymentLoader === true">
          <div style="height: 30rem; display: flex; justify-content: center; align-items: center;">
            <v-progress-circular
              :size="50"
              style="margin: auto;"
              color="accent"
              indeterminate
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      id="ipu-dialog"
      v-model="ipuDialog"
    >
      <CustomDialog
        :title="$t('Disc.thank_you')"
        title-icon="check_circle"
        :close-button="{
          id: 'close-purchase-dialog-btn',
          text: $t('Common.close')
        }"
        @close-event="ipuDialog = false"
      >
        <div slot="custom">
          <h3 v-if="unfinishedLink">
            {{ $t('Disc.your') }}
          </h3>
          <p v-if="unfinishedLink">
            {{ $t('Disc.clicking_2') }}
          </p>
          <p v-if="unfinishedLink">
            {{ $t('Disc.note') }}
          </p>
          <h3 v-if="ipuFeedback === true">
            {{ $t('Disc.feedback') }}
          </h3>
          <p v-if="ipuFeedback === true && !unfinishedLink">
            {{ $t('Disc.we_will_1') }}
          </p>
          <p v-if="unfinishedLink && ipuFeedback === true">
            {{ $t('Disc.we_will_2') }}
          </p>
        </div>
        <div
          v-if="unfinishedLink"
          slot="form"
          style="text-align: center;"
        >
          <a
            :href="unfinishedLink"
            target="_blank"
            rel="noreferrer"
            @click="ipuDialog = false"
          >
            {{ $t('Disc.start') }}
          </a>
        </div>
      </CustomDialog>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CustomDialog from '@/common/CustomDialog.vue'
import DiscPie from '@/pages/profile/components/DiscPie.vue'
import DiscBasicText from '@/pages/profile/edit/components/disc/DiscBasicText.vue'

import { colors } from '@/config/drivingForceColors.js'
import { personalities } from '@/config/personalityShort.js'
import { importedPersonality } from '@/config/personality.js'
import { importedDrivingForces } from '@/config/drivingForces.js'
import { importedCardStyle } from '@/config/purchaseCardStyle.js'
import { ADD_PAYMENT_CARD, CHANGE_PAYMENT_DETAILS } from '@/store/actions.type'

export default {
  name: 'DiscPayment',
  components: {
    DiscPie,
    DiscBasicText,
    CustomDialog
  },
  props: {
    isMotivations: { type: Boolean, default: false },
    isPersonality: { type: Boolean, default: false },
    createAlert: {
      type: Function,
      default: function () {
      }
    },
    ipuArticles: {
      type: Object,
      default: function () {
        return {}
      }
    },
    unfinishedLink: { type: String, default: '' }
  },
  data () {
    return {
      importedCardStyle: importedCardStyle,
      ipuDialog: false,
      infoPersonalityDialog: false,
      readMoreIpu: false,
      readMoreTabs: null,
      readMoreTabsMobile: null,
      paymentHandler: null,
      infoTabs: null,
      ipuFeedback: false,
      paymentLoader: false,
      choice: '',
      showPurchase: true,
      drivingForces: importedDrivingForces,
      personalitiesTypes: importedPersonality,
      personalityShort: personalities,
      colors: colors,
      ipuTabs: 0,
      discPaymentStepper: 1,
      elements: null,
      card: null,
      stripe: null,
      buttonLoader: false,
      packageToBuy: '',
      fullPrice: 0,
      ipuProduct: this.$store.state.products[0]
    }
  },
  computed: {
    ...mapState(['profile', 'user', 'products'])
  },
  created: function () {
  },
  mounted () {
    if (this.profile.ipu && this.profile.ipu.disc && this.profile.ipu.disc.length) {
      this.setHeight()
    }
  },
  methods: {
    setupPaymentMethod () {
      const object = {
        methods: {
          ipu: 'card'
        }
      }
      this.$http.put('payment/methods', object).then(() => {
        // this.purchaseIpu()
      }, err => {
        console.error(err)
        this.$root.$emit('errorMessage', err.response)
      })
    },
    purchase: function () {
      const self = this
      self.buttonLoader = true
      this.stripe.createToken(this.card).then(function (result) {
        if (result.error) {
          self.hasCardErrors = true
          self.buttonLoader = false
          self.$forceUpdate() // Forcing the DOM to update so the Stripe Element can update.
        } else {
          self.tableLoaderCards = true
          self.$store.dispatch(ADD_PAYMENT_CARD, { cardId: result.token.id }).then(() => {
            self.setupPaymentMethod()
            self.card.clear()
          }).catch(err => {
            console.error(err)
            self.buttonLoader = false
            self.$root.$emit('errorMessage', err.response)
            self.$root.$emit('changeLoader', false)
          })
        }
      })
    },
    purchaseIpu () {
      const object = {
        ipuVersion: this.choice
      }
      this.$http.post('store/ipu', object).then(res => {
        this.createAlert('Purchase complete', 'check_circle', 'success')
        this.readMoreIpu = false
        this.buttonLoader = false
        this.card.clear()
        this.handlePurchase(res)
      }).catch(err => {
        this.$root.$emit('errorMessage', err.response)
        this.buttonLoader = false
      })
    },
    setHeight () {
      if (document.getElementById('redbar') === undefined || document.getElementById('yellowbar') === undefined || document.getElementById('greenbar') === undefined || document.getElementById('bluebar') === undefined) return
      document.getElementById('redbar').setAttribute('style', 'height:' + this.profile.ipu.disc[0].d * 2 + 'px')
      document.getElementById('yellowbar').setAttribute('style', 'height:' + this.profile.ipu.disc[0].i * 2 + 'px')
      document.getElementById('greenbar').setAttribute('style', 'height:' + this.profile.ipu.disc[0].s * 2 + 'px')
      document.getElementById('bluebar').setAttribute('style', 'height:' + this.profile.ipu.disc[0].c * 2 + 'px')
    },
    toggleMotivations () {
      const postData = {
        visible: this.profile.ipu.ipuIsVisible
      }
      this.$http.put('profile/ipu', postData).then(res => {
        this.profile.ipu.ipuIsVisible = res.data.ipu.ipuIsVisible
      }, err => {
        console.error(err)
        this.createAlert('An error has occurred toggling the visibility of the behavioral assessment.', 'warning', 'error')
      })
    },
    getPersonalityText (name) {
      for (const i in this.personalityShort) {
        if (name === i) {
          return this.personalityShort[i]
        }
      }
    },
    getMotivationsText (name) {
      for (const i in this.drivingForces) {
        if (name === this.drivingForces[i].name) {
          return this.drivingForces[i].description
        }
      }
    },
    getColor (name) {
      for (const i in this.colors) {
        if (name === i) {
          return this.colors[i]
        }
      }
    },
    onBuyDisc () {
      // TODO: Refactor to use a more decisive way to find the "DISC" product
      // Find product that contains string "DISC" in the name
      const discProduct = this.products.find(product => product.name.toLowerCase().includes('disc'))
      const payload = {
        priceId: discProduct.priceId // 'price_1H8Z2pJZ2Z2Z2Z2Z2Z2Z2Z2Z'
      }
      this.$http.post('checkout/session', payload).then(res => {
        window.location.href = res.data.url
      }, err => {
        console.error('err', err)
        this.$root.$emit('errorMessage', err.response)
      })
    },
    purchaseIpuBasic () {
      this.packageToBuy = 'Profile Analysis'
      this.fullPrice = this.ipuProduct.total
    },
    handlePurchase (change) {
      if (this.choice === 'ipuBasic') {
        this.ipuProduct = null
      }
      setTimeout(() => {
        this.ipuFeedback = change.data.feedback
        this.showPurchase = false
        this.ipuDialog = true
        this.paymentLoader = false
      }, 500)
    },
    priceZero () {
      const object = {
        ipuVersion: this.choice
      }
      this.$store.dispatch(CHANGE_PAYMENT_DETAILS, object).then((res) => {
        this.readMoreIpu = false
        this.handlePurchase(res)
      }).catch((err) => {
        this.$root.$emit('errorMessage', err.response)
        this.paymentLoader = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#disc-stepper {
  .v-stepper__content {
    padding: 0;
  }
}
</style>
