var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-center cursor-pointer",attrs:{"id":"drivers-license"},on:{"click":function($event){_vm.currentPermits.drivingPermits = _vm.localPermits; _vm.permitDialog = true}}},[_c('v-icon',{staticClass:"icon-link",attrs:{"small":""},on:{"click":function($event){_vm.currentPermits.drivingPermits = _vm.localPermits; _vm.permitDialog = true}}},[_vm._v(" directions_car ")]),_c('span',{staticClass:"text-decoration-underline ml-1"},[_vm._v(_vm._s(_vm.$t('Pages.Profile.drivers_license'))+_vm._s(_vm.localPermits.length > 1 ? ': ' : (_vm.localPermits.length === 0 ? '' : ': ')))]),_vm._l((_vm.localPermits),function(license,index){return _c('span',{key:license.id,staticClass:"text-decoration-underline",attrs:{"id":'license-value-' + index}},[_vm._v(_vm._s(license)),(index !== _vm.localPermits.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])})],2),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.permitDialog),callback:function ($$v) {_vm.permitDialog=$$v},expression:"permitDialog"}},[_c('CustomDialog',{attrs:{"title-icon":"commute","title":_vm.$t('Pages.Profile.add_drivers'),"second-title":_vm.$t('Pages.Profile.pick_all'),"action-button":{
        id: 'license-confirm-btn',
        text: _vm.$t('Common.save'),
        class: 'cta-btn'
      },"close-button":{
        id: 'delete-license-cancel-btn',
        text: _vm.$t('Common.cancel')
      }},on:{"close-event":function($event){_vm.permitDialog = false},"perform-action":function($event){_vm.updateDrivingPermits(), _vm.permitDialog = false}}},[_c('div',{staticClass:"px-2",attrs:{"slot":"custom"},slot:"custom"},[_c('v-combobox',{staticClass:"px-5",attrs:{"items":_vm.permits,"label":_vm.$t('Pages.Profile.select'),"multiple":"","chips":"","color":"accent"},model:{value:(_vm.currentPermits.drivingPermits),callback:function ($$v) {_vm.$set(_vm.currentPermits, "drivingPermits", $$v)},expression:"currentPermits.drivingPermits"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }