<template>
  <div>
    <v-card
      color="neutral"
      class="elevation-1 rounded-xl"
    >
      <v-container>
        <v-row
          justify="center"
          class="profile-info"
        >
          <v-col
            cols="12"
            lg="10"
          >
            <v-container fluid>
              <v-row
                justify="center"
              >
                <v-col cols="12">
                  <ProfilePicture
                    v-if="profile"
                    :profile="profile"
                    :route="route"
                    :create-alert="createAlert"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="profile-account-info"
                >
                  <h2 class="text-center">
                    {{ user.firstName }} {{ user.lastName }}
                  </h2>
                  <!--<Availability
                    v-if="profile"
                    :profile="profile"
                    :route="route"
                    class="my-2"
                  />-->
                  <div class="flex-box my-2">
                    <div
                      id="add-location"
                      class="text-center add-location cursor-pointer"
                      @click="onOpenLocationDialog()"
                    >
                      <v-icon
                        small
                        class="icon-link"
                      >
                        place
                      </v-icon>
                      <span class="text-decoration-underline">
                        {{ profile && profile.locations.length > 0 ? profile.locations.join(', ') : $t('Pages.Search.location') }}
                      </span>
                    </div>
                  </div>
                  <div class="flex-box mt-2">
                    <DrivingLicenses
                      v-if="profile"
                      :profile="profile"
                      :route="route"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <hr class="skills-hr">
      <div class="my-1 profile-completion-container text-center">
        <v-tooltip
          bottom
          color="light-blue darken-4"
        >
          <template #activator="{ on }">
            <div
              class="progress-top"
              v-on="on"
            >
              <h4>{{ $t('Pages.Profile.profile_completion') }}</h4>
              <v-progress-circular
                v-if="profile && profile.completion && profile.completion.fraction !== null"
                :size="40"
                :width="5"
                :rotate="-90"
                :value="profile.completion.fraction * 100"
                color="accent"
              >
                {{ Math.floor(profile.completion.fraction * 100) }}%
              </v-progress-circular>
            </div>
          </template>
          <div v-if="profile && profile.completion && profile.completion.missing">
            <p
              v-if="profile.completion.missing && profile.completion.missing.length > 0"
              v-text="$t('Pages.Profile.improve_profile')"
            />
            <p
              v-else
              style="margin: 0;"
              v-text="$t('Pages.Profile.well_done')"
            />
            <div v-if="profile.completion.missing && profile.completion.missing.length > 0">
              <p
                v-for="field in profile.completion.missing"
                :key="field.id"
                style="margin: 0.2rem;"
              >
                <small>- {{ getText(field) }}</small>
              </p>
            </div>
          </div>
        </v-tooltip>
      </div>
    </v-card>

    <v-dialog
      v-model="locationDialog"
      max-width="500"
    >
      <CustomDialog
        :title="$t('Pages.Search.location')"
        title-icon="room"
        class="location-card"
      >
        <EditProfileLocation
          v-if="profile"
          slot="custom"
          :route="route"
          :profile="profile"
          :create-alert="createAlert"
          @close-location-dialog="closeLocationDialog"
        />
      </CustomDialog>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import CustomDialog from '@/common/CustomDialog.vue'
import EditProfileLocation from '@/pages/profile/edit/components/EditProfileLocation.vue'
import DrivingLicenses from '@/pages/profile/edit/components/EditProfileLicense.vue'
import ProfilePicture from '@/pages/profile/edit/components/EditProfilePicture.vue'

import { fields, fieldsSwedish } from '@/config/profileCompletion.js'
import { rules } from '@/config/validation-rules.js'

export default {
  name: 'AccountInfoCard',
  components: {
    CustomDialog,
    EditProfileLocation,
    ProfilePicture,
    DrivingLicenses
  },
  props: {
    createAlert: {
      type: Function,
      default: () => {
      }
    },
    saveContent: {
      type: Function,
      default: () => {
      }
    },
    route: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      linkInput: { title: null, value: null },
      links: [],
      fields: fields,
      fieldsSwedish: fieldsSwedish,
      locationDialog: false,
      importedRules: rules
    }
  },
  computed: {
    ...mapState([
      'profile',
      'user'
    ])
  },
  methods: {
    closeLocationDialog () {
      this.locationDialog = false
    },
    onOpenLocationDialog () {
      this.locationDialog = true
    },
    getText (field) {
      for (const i in this.fields) {
        if (field === i) {
          if (this.$i18n.locale === 'sv') {
            return this.fieldsSwedish[i]
          } else {
            return this.fields[i]
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.mobile-overflow-x {
  overflow-x: auto;
}
</style>
