<template>
  <div
    class="skill-boxes top-card"
    :class="{ 'fill-height-card': fillHeight }"
  >
    <v-card
      color="neutral"
      class="elevation-1 margin-all-0 rounded-xl"
      :class="{ 'fill-height-card': fillHeight }"
    >
      <slot name="star" />
      <v-container fluid>
        <v-row>
          <v-col
            class="first-column relative-flex d-flex"
          >
            <div class="card-content">
              <h3>
                <v-icon
                  v-if="icon"
                  class="mr-2"
                >
                  {{ icon }}
                </v-icon>
                <img
                  v-if="imageUrl"
                  :src="imageUrl"
                  class="mr-2 profile-card-image"
                >
                {{ title }}
              </h3>
              <div>
                {{ subtitle }}
              </div>
              <span>{{ secondLineSubtitle }}</span>
              <div>
                {{ description }}
              </div>
              <slot name="tooltip" />
            </div>
            <div class="action-buttons-profile">
              <v-menu
                color="neutral"
                bottom
                left
                rounded
              >
                <template #activator="{ on }">
                  <v-btn
                    v-if="menuId"
                    :id="menuId"
                    class="ma-0"
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list
                  v-if="list"
                  color="neutral"
                  dense
                >
                  <template v-for="listItem in list">
                    <v-list-item
                      v-if="listItem !== null"
                      :id="listItem.id"
                      :key="listItem.id"
                      @click="handleClickEvent(listItem.clickEvent)"
                    >
                      <v-list-item-title>{{ listItem.text }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </div>
            <div class="action-buttons-profile">
              <slot name="action-buttons" />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <slot name="custom" />
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'ProfileCard',
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    secondLineSubtitle: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    },
    fillHeight: {
      type: Boolean,
      default: false
    },
    menuId: {
      type: String,
      default: () => {}
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {

    }
  },
  methods: {
    handleClickEvent (event) {
      this.$emit(event)
    }
  }
}
</script>
