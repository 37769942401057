<template>
  <div>
    <ProfileTitle
      v-if="profile"
      :profile="profile"
      :title="$t('Pages.Profile.languages')"
      array="languages"
      button-id="language"
      @button-event="addLanguage"
    />
    <transition-group
      id="language-container"
      mode="out-in"
      name="slide-y-transition"
      tag="v-layout"
      class="row wrap"
    >
      <v-col
        v-for="(language, index) in profile.languages"
        :id="'language-' + index"
        :key="language + '-' + index"
        cols="12"
        sm="4"
      >
        <ProfileCard
          :image-url="language.flagUrl"
          :title="language.language"
          :subtitle="getLanguageLevel(language.level)"
          :fill-height="true"
          :menu-id="'more-language-' + index"
          :list="[
            {
              id: 'delete-language-' + index,
              clickEvent: 'delete-item',
              text: $t('Common.delete')
            },
          ]"
          @edit-item="editLanguage(language, index)"
          @delete-item="itemToDelete = language, deleteLanguageDialog = true"
        />
      </v-col>
    </transition-group>
    <transition name="slide-y-transition">
      <EmptySectionText
        v-if="!profile.languages || !profile.languages.length"
        icon="translate"
        :title="$t('Pages.Profile.studied')"
      />
    </transition>
    <v-dialog
      v-model="languageDialog"
      content-class="rounded-xl"
    >
      <div
        is="CustomDialog"
        title-icon="add_circle"
        :title="$t('Pages.Profile.add_a_language')"
      >
        <div slot="custom">
          <div :id="'language-name-input-' + currentLang.index">
            <v-text-field
              id="language-input"
              v-model="currentLang.language"
              outlined
              rounded
              dense
              color="accent"
              autofocus
              :label="$t('Pages.Profile.language')"
            />
            <v-select
              :id="'language-rate-input-' + currentLang.index"
              v-model="currentLang.level"
              :menu-props="{allowOverflow:false}"
              :items="langValues"
              color="accent"
              :label="$t('Pages.Profile.level')"
              required
            />
          </div>
        </div>
        <div
          slot="buttons"
          class="button-container"
        >
          <v-btn
            id="close-lang-btn"
            text
            rounded
            @click="languageDialog = false"
            v-text="$t('Common.cancel')"
          />
          <v-btn
            id="save-lang-btn"
            text
            rounded
            :disabled="(currentLang.language === '' && currentLang.level === '') || (currentLang.language === '' || currentLang.level === '') || (languageErrors && languageErrors.length > 0)"
            class="cta-btn"
            @click="formatInput()"
            v-text="$t('Common.save')"
          />
        </div>
      </div>
    </v-dialog>
    <v-dialog
      v-model="deleteLanguageDialog"
      content-class="rounded-xl"
      max-width="500"
    >
      <CustomDialog
        title-icon="delete"
        :title="$t('Pages.Profile.delete_language')"
        :second-title="$t('Pages.Profile.delete_language_text')"
        :action-button="{
          class: 'deleteAction',
          id: 'delete-language-confirm-btn',
          text: $t('Common.delete')
        }"
        :close-button="{
          id: 'delete-cancel-btn',
          text: $t('Common.cancel')
        }"
        @close-event="deleteLanguageDialog = false"
        @perform-action="handleDelete"
      />
    </v-dialog>
  </div>
</template>

<script>
import EmptySectionText from '@/pages/profile/edit/components/EditProfileEmptySection.vue'
import ProfileTitle from '@/pages/profile/edit/components/EditProfileTitles.vue'
import ProfileCard from '@/pages/profile/components/ProfileCard.vue'
import CustomDialog from '@/common/CustomDialog.vue'
import EventBus from '@/common/EventBus.js'
export default {
  components: {
    ProfileTitle,
    ProfileCard,
    CustomDialog,
    EmptySectionText
  },
  props: {
    profile: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      langList: [],
      currentLang: '',
      languageDialog: false,
      languageErrors: [],
      searchLanguages: '',
      showError: false,
      langValues: [
        { text: this.$t('Common.native'), value: 5 },
        { text: this.$t('Common.fluent'), value: 4 },
        { text: this.$t('Common.conversational'), value: 3 },
        { text: this.$t('Common.intermediate'), value: 2 },
        { text: this.$t('Common.elementary'), value: 1 }
      ],
      deleteLanguageDialog: false,
      itemToDelete: null
    }
  },
  watch: {
    'currentLang.language': function (newVal) {
      if (!newVal) return
      if (newVal.length && newVal.replace(/^\s+|\s+$/gm, '').length < 1) {
        this.languageErrors = 'Cannot contain only spaces'
      } else {
        this.languageErrors = []
      }
    }
  },
  created () {
    EventBus.$on('duplicateErrorslanguages', (error) => {
      this.languageErrors = error
    })
    EventBus.$on('savedlanguages', (keepOpen) => {
      if (!keepOpen) {
        this.languageDialog = false
      }
    })
    EventBus.$on('deleteDialoglanguages', () => {
      this.deleteLanguageDialog = false
    })
  },
  beforeDestroy () {
    EventBus.$off('duplicateErrorslanguages')
    EventBus.$off('savedlanguages')
    EventBus.$off('deleteDialoglanguages')
  },
  methods: {
    formatInput (keepOpen) {
      this.$emit('save-content', this.currentLang, 'languages', keepOpen)
      if (keepOpen) {
        this.currentLang = { language: '', level: '', index: this.profile.languages.length + 1 }
      }
    },
    handleDelete () {
      // console.log('delete', this.itemToDelete)
      this.$emit('delete-item', this.itemToDelete, 'languages')
      this.deleteLanguageDialog = false
    },
    getLanguageLevel (level) {
      const levels = {
        1: this.$t('Common.elementary'),
        2: this.$t('Common.intermediate'),
        3: this.$t('Common.conversational'),
        4: this.$t('Common.fluent'),
        5: this.$t('Common.native')
      }
      for (const i in levels) {
        if (level == i) { // eslint-disable-line
          // String and int comparison, dont change the '=='
          return levels[i]
        }
      }
    },
    editLanguage (language, index) {
      this.langList.push(language.language)
      this.currentLang = { language: language.language, level: language.level, index: index }
      this.languageDialog = true
    },
    addLanguage () {
      this.langErrors = []
      this.currentLang = { language: '', level: '', index: this.profile.languages.length }
      this.languageDialog = true
    },
    removeFocus (ref, list) {
      if (!this[list]) return
      if (this[list].length === 1) {
        this.$refs[ref].blur()
      }
    }
  }
}
</script>
