<template>
  <div
    class="py-4 text-center grey--text darken-2"
  >
    <v-icon
      class="mb-2"
      large
      :color="$store.state.isAuthenticated ? '' : 'neutral'"
    >
      {{ icon }}
    </v-icon>
    <!-- eslint-disable -->
    <h4 v-html="title"></h4>
    <!-- eslint-enable -->
    <p>
      {{ text }}
      <v-icon
        v-if="helpIcon"
        class="help-icon"
        @click="openDialog()"
      >
        {{ helpIcon }}
      </v-icon>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    helpIcon: {
      type: String,
      default: ''
    }
  },
  methods: {
    openDialog () {
      this.$emit('click-event')
    }
  }
}
</script>

<style>

</style>
