<template>
  <div>
    <div
      id="drivers-license"
      class="text-center cursor-pointer"
      @click="currentPermits.drivingPermits = localPermits; permitDialog = true"
    >
      <v-icon
        small
        class="icon-link"
        @click="currentPermits.drivingPermits = localPermits; permitDialog = true"
      >
        directions_car
      </v-icon>
      <span class="text-decoration-underline ml-1">{{ $t('Pages.Profile.drivers_license') }}{{ localPermits.length > 1 ? ': ' : (localPermits.length === 0 ? '' : ': ') }}</span>
      <span
        v-for="(license, index) in localPermits"
        :id="'license-value-' + index"
        :key="license.id"
        class="text-decoration-underline"
      >{{ license }}<span v-if="index !== localPermits.length - 1">, </span>
      </span>
    </div>
    <v-dialog
      v-model="permitDialog"
      max-width="500"
    >
      <CustomDialog
        title-icon="commute"
        :title="$t('Pages.Profile.add_drivers')"
        :second-title="$t('Pages.Profile.pick_all')"
        :action-button="{
          id: 'license-confirm-btn',
          text: $t('Common.save'),
          class: 'cta-btn'
        }"
        :close-button="{
          id: 'delete-license-cancel-btn',
          text: $t('Common.cancel')
        }"
        @close-event="permitDialog = false"
        @perform-action="updateDrivingPermits(), permitDialog = false"
      >
        <div
          slot="custom"
          class="px-2"
        >
          <v-combobox
            v-model="currentPermits.drivingPermits"
            :items="permits"
            :label="$t('Pages.Profile.select')"
            multiple
            chips
            color="accent"
            class="px-5"
          />
        </div>
      </CustomDialog>
    </v-dialog>
  </div>
</template>

<script>
import CustomDialog from '@/common/CustomDialog.vue'
export default {
  components: {
    CustomDialog
  },
  props: {
    profile: {
      type: Object,
      default: () => {}
    },
    route: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      currentPermits: {},
      permitDialog: false,
      permits: [
        'AM',
        'A1',
        'A2',
        'A',
        'B1',
        'B',
        'C1',
        'C',
        'D1',
        'D',
        'BE',
        'C1E',
        'CE',
        'D1E',
        'DE'
      ]
    }
  },
  beforeMount () {
    this.localPermits = this.profile.drivingPermits
  },
  methods: {
    updateDrivingPermits () {
      // Sort the currentPermits.drivingPermits array based on the order of the permits array
      this.currentPermits.drivingPermits.sort((a, b) => {
        return this.permits.indexOf(a) - this.permits.indexOf(b)
      })

      this.$http.put(this.route + '/drivingpermits', { drivingPermits: this.currentPermits.drivingPermits })
        .then(
          res => {
            this.currentPermits = res.data.profile.drivingPermits
            this.localPermits = res.data.profile.drivingPermits
          },
          err => {
            this.$root.$emit('errorMessage', err.response)
          }
        )
    }
  }
}
</script>
