<template>
  <div class="profile-pic-container">
    <div
      class="profile-content"
      style="height: 160px; width: 160px;"
    >
      <v-img
        :src="pictureUrl"
        style="border-radius: 50%;"
        aspect-ratio="1"
        class="grey lighten-2"
        max-width="160"
        max-height="160"
      />
      <div class="button-container">
        <v-btn
          id="profilePhoto"
          class="elevation-2"
          color="primary"
          fab
          small
          @click="picDialog = true"
        >
          <v-icon>photo_camera</v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="picDialog"
      max-width="500"
    >
      <div
        is="CustomDialog"
        :title="$t('Pages.Profile.update_picture')"
      >
        <div
          slot="custom"
          class="flex"
          style="text-align: center;"
        >
          <croppa
            id="crop-img"
            ref="myCroppa"
            :file-size-limit="10000000"
            :width="200"
            :height="200"
            :placeholder="$t('Pages.Profile.choose_image')"
            :show-loading="true"
            :zoom-speed="10"
            @file-size-exceed="handleFileSizeExceed"
          />
          <div v-text="$t('Pages.Profile.max_size')" />
        </div>
        <div
          slot="buttons"
          class="button-container"
        >
          <v-btn
            id="cancel-btn"
            text
            rounded
            @click="picDialog = false"
            v-text="$t('Common.cancel')"
          />
          <v-btn
            id="crop-img-btn"
            class="cta-btn"
            text
            rounded
            :loading="savePicLoader"
            :disabled="savePicLoader"
            @click="getPhoto"
            v-text="$t('Common.save')"
          />
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CustomDialog from '@/common/CustomDialog.vue'
import { CHANGE_PICTURE, UPDATE_COMPLETION } from '@/store/actions.type'
export default {
  components: {
    CustomDialog
  },
  props: {
    createAlert: {
      type: Function,
      default: () => {}
    },
    route: {
      type: String,
      required: true
    },
    profile: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      picDialog: false,
      savePicLoader: false
    }
  },
  computed: {
    ...mapState({
      pictureUrl: state => state.user.picture.url
    })
  },
  methods: {
    handleFileSizeExceed () {
      this.createAlert('The uploaded file is too big. Max 10MB.', 'priority_high', 'warning')
      this.$refs.myCroppa.remove()
    },
    getPhoto () {
      const files = {
        picture: this.$refs.myCroppa.generateDataUrl()
      }
      this.crop(files)
    },
    crop (files) {
      this.savePicLoader = true
      this.$http.post(this.route + '/pictures', files).then(
        res => {
          this.$store.dispatch(CHANGE_PICTURE, res.data.profile)
          this.$store.dispatch(UPDATE_COMPLETION, { completion: res.data.profile.completion })
          this.picDialog = false
          this.savePicLoader = false
        },
        err => {
          console.error(err)
          this.savePicLoader = false
          this.$root.$emit('errorMessage', err.response)
        }
      )
    }
  }
}
</script>
