<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="profileLoaded">
        <ProfileEditView
          :create-alert="createAlert"
          :route="route"
        />
      </div>
      <div v-else>
        <v-responsive style="height: calc(100vh - 64px);">
          <PageLoader />
        </v-responsive>
      </div>
    </transition>
  </div>
</template>

<script>
import ProfileEditView from '@/pages/profile/edit/components/EditProfileView.vue'
import { GET_PROFILE } from '@/store/actions.type'
import PageLoader from '@/common/PageLoader.vue'

export default {
  name: 'EditProfile',
  components: {
    PageLoader,
    ProfileEditView
  },
  props: {
    createAlert: {
      type: Function,
      default: function () {
      }
    }
  },
  data () {
    return {
      profileLoaded: false,
      error: null,
      route: 'profile'
    }
  },
  created () {
    this.$store.dispatch(GET_PROFILE).then(() => {
      this.profileLoaded = true
    }).catch((error) => {
      this.$root.$emit('errorMessage', error)
    })
  }
}
</script>
