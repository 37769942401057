<template>
  <h4 class="resume-titles">
    {{ title }} <transition name="slide-y-transition">
      <v-chip
        v-show="!profile[array] || !profile[array].length"
        class="ml-2 padding-x-6-height-auto elevation-1"
        color="orange accent-3"
        label
        small
        v-text="$t('Pages.Profile.incomplete')"
      />
    </transition>
    <v-icon
      v-if="profile[array] && profile[array].length && help"
      class="help-icon ml-2"
      @click="sendIconEvent()"
    >
      help
    </v-icon>
    <v-spacer />
    <v-tooltip
      color="light-blue darken-4"
      bottom
    >
      <template #activator="{ on }">
        <v-btn
          :id="'add-' + buttonId"
          fab
          color="primary"
          small
          class="elevation-2 add-btn"
          v-on="on"
          @click="sendButtonEvent()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span v-text="$t('Common.add')" />
    </v-tooltip>
  </h4>
</template>

<script>
export default {
  props: {
    profile: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    array: {
      type: String,
      default: ''
    },
    buttonId: {
      type: String,
      default: ''
    },
    help: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    sendButtonEvent () {
      this.$emit('button-event')
    },
    sendIconEvent () {
      this.$emit('icon-event')
    }
  }
}
</script>
