var importedCardStyle = {
  base: {
    color: '#fff',
    // lineHeight: '18px',
    fontFamily: '"Open sans", sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#fff'
    },
    iconColor: '#FFF'
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  },
  card: {
    iconStyle: 'solid'
  }
}

export { importedCardStyle }
