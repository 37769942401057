const fields = {
  picture: 'Profile picture',
  skills: 'Skills & Tools',
  languages: 'Languages',
  locations: 'Locations',
  educations: 'Educations',
  experiences: 'Experiences & Assignments',
  description: 'Description'
}

const fieldsSwedish = {
  picture: 'Profilbild',
  skills: 'Kompetenser',
  languages: 'Språk',
  locations: 'Platser',
  educations: 'Utbildningar',
  experiences: 'Erfarenheter & Uppdrag',
  description: 'Beskrivning'
}

export { fields, fieldsSwedish }
