<template>
  <div>
    <p>{{ $t('Pages.Search.search_select') }}</p>
    <LocationCombobox
      :selected-locations.sync="localSelectedLocationItems"
    />
    <div class="button-container">
      <v-btn
        id="cancel-location-btn"
        text
        rounded
        @click="onClose()"
      >
        {{ $t('Common.close') }}
      </v-btn>
      <v-btn
        id="confirm-location-btn"
        :loading="saveLoader"
        :disabled="saveLoader"
        class="cta-btn"
        text
        rounded
        @click="saveLocations()"
        v-text="$t('Common.save')"
      />
    </div>
  </div>
</template>
<script>
import LocationCombobox from '@/common/LocationCombobox.vue'
import { UPDATE_COMPLETION } from '@/store/actions.type'

export default {
  name: 'EditProfileLocation',
  components: {
    LocationCombobox
  },
  props: {
    createAlert: {
      type: Function,
      default: () => {
      }
    },
    closeLocationDialog: {
      type: Function,
      default: () => {
      }
    },
    updateLocation: {
      type: Function,
      default: () => {
      }
    },
    profile: {
      type: Object,
      required: true
    },
    route: {
      type: String,
      required: true
    },
    selectedLocationItems: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      saveLoader: false,
      friendlyLocation: '',
      localSelectedLocationItems: []
    }
  },
  watch: {
    _selectedLocationItems: function (newVal, oldVal) {
    }
  },
  mounted () {
    this.localSelectedLocationItems = this.profile.locations
  },
  methods: {
    onClose () {
      this.$emit('close-location-dialog')
    },
    saveLocations () {
      this.savedState = true
      this.saveLoader = true
      this.$http.put(this.route + '/location', { locations: this.localSelectedLocationItems }).then(
        (res) => {
          // Close dialog
          this.$emit('close-location-dialog')
          // this.$emit('save-locations', this.localSelectedLocationItems)
          this.$store.dispatch(UPDATE_COMPLETION, { completion: res.data.profile.completion })
          // eslint-disable-next-line vue/no-mutating-props
          this.profile.locations = this.localSelectedLocationItems
          // this.profile.locations = this.localSelectedLocationItems
          this.savedState = false
          this.saveLoader = false
        },
        (err) => {
          console.error(err)
          this.savedState = false
          this.saveLoader = false
          this.$root.$emit('errorMessage', err.response)
        }
      )
    }
  }
}
</script>
