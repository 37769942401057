<template>
  <div>
    <h4
      class="resume-titles mb-3"
      v-text="$t('Pages.Profile.short_description')"
    />
    <div class="top-card mb-3">
      <v-card
        class="pa-3 rounded-xl"
        color="neutral"
      >
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-textarea
            id="profile-description"
            v-model="description"
            :maxlength="500"
            :counter="500"
            color="accent"
            class="description"
            outlined
            :label="$t('Pages.Profile.describe')"
            @focus="isEditing = true"
            @keydown="isChanged = true"
          />
          <template v-if="isEditing">
            <v-btn
              id="cancel-save-description"
              text
              rounded
              @click="cancel"
              v-text="$t('Common.cancel')"
            />
            <v-btn
              id="save-description"
              class="mr-4 cta-btn"
              :loading="isLoading"
              :disabled="isLoading || !isChanged"
              rounded
              @click="save"
              v-text="$t('Common.save')"
            />
          </template>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import { UPDATE_COMPLETION } from '@/store/actions.type'
export default {
  name: 'ProfileDescription',
  props: {
    profile: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isChanged: false,
      isEditing: false,
      valid: true,
      description: this.profile.description ? this.profile.description : '',
      isLoading: false
    }
  },
  methods: {
    save () {
      if (this.$refs.form.validate()) {
        this.isLoading = true
        this.$http.put('profile/personaldescription', { description: this.description }).then(
          (res) => {
            this.isEditing = false
            this.isLoading = false
            // this.profile.description = this.description
            this.$store.dispatch(UPDATE_COMPLETION, { completion: res.data.profile.completion })
          },
          err => {
            console.error(err)
            this.isLoading = false
          }
        )
      }
    },
    cancel () {
      this.description = this.profile.description
      this.isEditing = false
      this.isChanged = false
    }
  }
}
</script>
